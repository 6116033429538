<template>
	<div class="store-container">
		<el-row>
			<el-col :span='1'>
				<div class='store-title'>
					<button class="edit-btn" @click="edit">
						<span><img src="../assets/img/no-edit.png"></span>
						<span>返回</span>
					</button>
				</div>
			</el-col>
			<el-col :span='20' :offset='3'>
				<div class="store-from">
					<div class="store-from-title">请填写以下信息：</div>
					<el-form class="store-from-content" ref="form" :rules="rules" :model="form" label-width="120px">
						<el-form-item :label="util.getKeyFrontValue('cover') + ':'" prop="cover">
							<el-upload action :http-request="coverUpload" list-type="picture-card"
								:on-preview="handlePictureCardPreview" :before-upload="beforeAvatarUpload"
								:on-exceed="handleExceed" :limit="1" :on-remove="handleRemove"
								:file-list="coverImgList">
								<div class="add-img">
									<img src="../assets/img/add-img.png" alt="">
									<div>点击上传</div>
								</div>
								<div slot="tip" class="el-upload__tip" :style="coverImgList.length > 0 ? 'margin-top: -20px;' : ''">支持jpg，jpeg，png格式，大小不得超过5M</div>
							</el-upload>
							<div>
								<el-progress v-if="showProgress" :percentage="progress" :color="color"
									style="width: 100px">
								</el-progress>
							</div>
						</el-form-item>
						<el-form-item :label="util.getKeyFrontValue('name') + ':'" prop="title">
							<el-row>
								<el-col :span='15'>
									<el-input class="w-600" v-model="form.title" maxlength="50" show-word-limit :placeholder="'请输入' + util.getKeyFrontValue('name')">
									</el-input>
								</el-col>
							</el-row>
						</el-form-item>
						<!-- 课程主题 -->
						<el-form-item :label="util.getKeyFrontValue('category') + ':'" prop="course_category_id">
							<el-select class="w-200 comment-margin-right-10" size="small" v-model="form.course_type"
								:placeholder="'请选择' + util.getKeyFrontValue('category')">
								<el-option label="劳动教育" value="labor"></el-option>
								<el-option label="综合实践" value="comprehensive"></el-option>
							</el-select>
							<el-select class="w-200" size="small" v-model="form.course_category_id"
								placeholder="请选择" v-show="form.course_type == 'labor'">
								<el-option v-for="(item,index) in laborOption" :key="item.id" :value="item.id" :label="item.name">
								</el-option>
							</el-select>
							<el-select class="w-200" size="small" v-model="form.course_category_id" placeholder="请选择"
								v-show="form.course_type == 'comprehensive'">
								<el-option v-for="(item,index) in comprehensiveOption" :key="item.id" :value="item.id"
									:label="item.name"></el-option>
							</el-select>
							
							<!-- <el-select filterable v-model="form.theme" clearable :placeholder="'请选择' + util.getKeyFrontValue('category')" value-key="id">
								<el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.kind">
								</el-option>
							</el-select>
							<el-select filterable v-model="form.theme" clearable placeholder="请选择" value-key="id">
								<el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.kind">
								</el-option>
							</el-select> -->
						</el-form-item>
						<!-- <el-form-item label="学时:" prop="hour">
							<el-row>
								<el-col :span='5'>
									<el-input v-model.number="form.hour"  show-word-limit placeholder="请输入学时">
									</el-input>
								</el-col>
							</el-row>
						</el-form-item> -->
						<el-form-item :label="util.getKeyFrontValue('apply') + ':'" prop="stages">
							<el-checkbox-group v-model="form.stages" class="el-checkbox-style" @change="stageChange">
								<el-checkbox label="小学">小学</el-checkbox>
								<el-checkbox label="初中">初中</el-checkbox>
							    <el-checkbox label="高中">高中</el-checkbox>
							</el-checkbox-group>
							<!-- <el-radio-group v-model="form.stage">
								<el-radio label="1">小学段</el-radio>
								<el-radio label="2">初中段</el-radio>
								<el-radio label="3">高中段</el-radio>
							</el-radio-group> -->
						</el-form-item>
						<!-- <el-form-item label="参赛地区:" prop="regin">
							<el-select filterable v-model="form.regin" clearable placeholder="请选择(可输入搜索)"
								value-key="id">
								<el-option v-for="item in reginOptions" :key="item.id" :label="item.name" :value="item">
								</el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item :label="util.getKeyFrontValue('introduction') + ':'" prop="viewpoint">
							<span slot="label">
								<span class="span-box">
									<span>{{util.getKeyFrontValue('introduction') + ':'}}</span>
									<!-- <p class="text-color" style="margin-top: -15px;">(300字以内)</p> -->
								</span>
							</span>
							<el-input type="textarea" :placeholder="'请输入' + util.getKeyFrontValue('introduction')" v-model="form.viewpoint" rows='10'
								maxlength="300" show-word-limit style="width: 610px;">
							</el-input>
						</el-form-item>
						<!-- 课程正文 -->
						<!-- <el-form-item :label="util.getKeyFrontValue('description') + ':'" prop="description" v-show="is_permit_description">
							<div style="width: 610px;">
								<editor @editorHtml='getElem' :editorContent.sync='form.description'></editor>
							</div>
						</el-form-item> -->
						<el-form-item label="作者自荐:" prop="introduction" v-if="is_permit_author_recommendation">
							<el-input type="textarea" placeholder="请输入作者自荐" v-model="form.introduction" rows='10'
								maxlength="300" show-word-limit style="width: 610px;">
							</el-input>
							<!-- <div class="introduction" style="width: 610px;">
								<div class="introduction-check-content">
									<div v-for="(item,index) in list"
										:class="listCheck.includes(item.id) ? 'introduction-check-active':'introduction-check'"
										@click="selectChecked(item.id)">{{item.name}}</div>
								</div>
								<div class="introduction-line"></div>
								<editor @editorHtml='getElem' :editorContent.sync='form.introduction'></editor>
							</div> -->
						</el-form-item>
						<el-form-item :label="util.getKeyFrontValue('video') + ':'" prop="videoList" class="upload-demo" v-if="is_permit_video">
							<div class="upload-button">
								<el-button @click="showMoBan('video')">查看示例</el-button>
							</div>
							<el-upload action drag multiple :http-request="videoUpload" :file-list="videoList"
								:before-upload="beforeVideoUpload">
								<div class="el-upload__text">
									<em style="margin-right: 20px">
										<img src="../assets/img/upload.png" style="width: 16px;height: 15px;margin-right: 10px">点击上传 
									</em>
									<span style="margin-right: 20px;color: #E5E5E5;">|</span>拖动文件到框内
									<p>支持MP4格式，单个视频文件大小限制1024M</p>
								</div>
							</el-upload>
							<div class="my-upload-list">
								<div v-for="(item,index) in videoList">
									<div class="upload-flex">
										<img :src="util.setFileIcon(item.path)" class='my-file'>
										<span>{{ item.name.length>15 ? item.name.substr(0, 15)+'...':item.name }}({{item.size}})</span>
									</div>
									<div>
										<span @click='showFile(item.path)' class="show-flie-open">预览</span>
										<span class="my-close" @click="delList('video',item.id)">删除</span>
									</div>
								</div>
							</div>
							<div>
								<el-progress v-if="showProgressVideo" :percentage="progressVideo" :color="color"
									style="width: 600px">
								</el-progress>
							</div>
						</el-form-item>
						<el-form-item :label="util.getKeyFrontValue('audio') + ':'" prop="audioList" class="upload-demo" v-if="is_permit_audio">
							<div class="upload-button">
								<el-button @click="showMoBan('audio')">查看示例</el-button>
							</div>
							<el-upload action drag multiple :http-request="audioUpload" :file-list="audioList"
								:before-upload="beforeAudioUpload">
								<div class="el-upload__text">
									<em style="margin-right: 20px">
										<img src="../assets/img/upload.png" style="width: 16px;height: 15px;margin-right: 10px">点击上传 
									</em>
									<span style="margin-right: 20px;color: #E5E5E5;">|</span>拖动文件到框内
									<!-- <p>支持MP3格式，单个音频文件大小限制500M</p> -->
									<p>支持多个文件上传，格式仅支持PPT文件，单个文件大小50M以内</p>
								</div>
							</el-upload>
							<div class="my-upload-list">
								<div v-for="(item,index) in audioList">
									<div class="upload-flex">
										<img :src="util.setFileIcon(item.path)" class='my-file'>
										<span>{{ item.name.length>15 ? item.name.substr(0, 15)+'...':item.name }}({{item.size}})</span>
									</div>
									<div>
										<span @click='showFile(item.path)' class="show-flie-open">预览</span>
										<span class="my-close" @click="delList('audio',item.id)">删除</span>
									</div>
								</div>
							</div>
							<div>
								<el-progress v-if="showProgressAudio" :percentage="progressAudio" :color="color"
									style="width: 600px">
								</el-progress>
							</div>
						</el-form-item>
						<el-form-item :label="util.getKeyFrontValue('file') + ':'" :prop="is_require_file ? 'fileList' : ''" class="upload-demo">
							<div class="upload-button">
								<el-button @click="showMoBan('file')">查看示例</el-button>
							</div>
							<el-upload action drag multiple :http-request="success" :file-list="filesList"
								:before-upload="beforeAvatarUpload2">
								<div class="el-upload__text">
									<em style="margin-right: 20px">
										<img src="../assets/img/upload.png" style="width: 16px;height: 15px;margin-right: 10px">点击上传
									</em>
									<span style="margin-right: 20px;color: #E5E5E5;">|</span>拖动文件到框内
									<p>支持多个文件上传，格式仅支持Word文件，单个文件大小50M内。</p>
								</div>
							</el-upload>
							<div class="my-upload-list">
								<div v-for="(item,index) in filesList">
									<div class="upload-flex">
										<img :src="util.setFileIcon(item.path)" class='my-file'>
										<span>{{ item.name.length>15 ? item.name.substr(0, 15)+'...':item.name }}({{item.size}})</span>
									</div>
									<div>
										<span @click='showFile(item.path)' class="show-flie-open">预览</span>
										<span class="my-close" @click="delList('file',item.id)">删除</span>
									</div>
								</div>
							</div>
							<div>
								<el-progress v-if="showProgress2" :percentage="progress2" :color="color"
									style="width: 610px">
								</el-progress>
							</div>
						</el-form-item>
						<div class="store-from-title">请填写参赛人信息：</div>
						<el-form-item label="姓名:" prop="infoName">
							<el-row>
								<el-col :span='10'>
									<el-input v-model="form.infoName" placeholder="请输入参赛人信息"></el-input>
								</el-col>
							</el-row>
						</el-form-item>
						<el-form-item label="单位类型:" prop="affiliationType">
							<el-row>
								<el-col :span='10'>
									<el-select v-model="form.affiliationType" style="width: 100%;" :disabled="true" @change="affiliationTypeChange">
										<el-option v-for="item in affiliationOptions" :label="item.name" :value="item.value"></el-option>
									</el-select>
								</el-col>
							</el-row>
						</el-form-item>
						<el-form-item label="所属单位:" prop="affiliation">
							<el-select filterable v-model="form.municipal_code" placeholder="请选择"
								value-key="id" class="w-100">
								<el-option v-for="item in municipalOption" :key="item.id" :label="item.name"
									:value="item">
								</el-option>
							</el-select>
							<span style="margin: 0 10px;">市</span>
							<el-select filterable v-model="form.county_code" clearable placeholder="请选择"
								value-key="id" class="w-100">
								<el-option v-for="item in countyOption" :key="item.id" :label="item.name"
									:value="item">
								</el-option>
							</el-select>
							<span style="margin: 0 10px;">区/县</span>
							<el-input v-model="form.affiliation" placeholder="请输入学校名称" class="w-300"></el-input>
							<!-- <el-row>
								<el-col :span='10'>
									<el-input v-if="form.affiliationType != 1" v-model="form.affiliation" placeholder="请输入所属单位"></el-input>
									<el-select v-else v-model="form.affiliation" popper-class='select export-drop-down-select-class' style="width: 100%;" @change="schoolSelectChange">
										<div style="position: absolute;z-index: 20;top: 10px;left: 10px;right: 10px;">
											<el-row class="el_input_top_border">
												<el-input placeholder="请输入关键字进行搜索" v-model="search_option_input"
													class="">
													<div slot="suffix" class="search_style">
														<span style="font-size: 14px;color: #5485FC;cursor:pointer;"
															@click="getSchoolList('search')">搜索</span>
													</div>
												</el-input>
											</el-row>
										</div>
										<el-option v-for="(item,index) in schoolList" :key="index" v-show="index < (page * 15 < schoolTotal ? 15 : schoolTotal % 15)" :label="item.name" :value="item.id"></el-option>
										<div class="text-content">
											<div class="text" @click="prevPage">上一页</div>
											<div class="text" @click="nextPage">下一页</div>
										</div>
									</el-select>
								</el-col>
							</el-row> -->
						</el-form-item>
						<!-- <el-form-item label="所属教育局:" prop="county_code" v-if="is_permit_confirm_region_code">
							<el-row>
								<el-col :span="4">
									<el-select filterable v-model="form.municipal_code" placeholder="请选择"
										value-key="id">
										<el-option v-for="item in municipalOption" :key="item.id" :label="item.name"
											:value="item">
										</el-option>
									</el-select>
								</el-col>
								<el-col :span="1" style='margin-left: 5px;'>
									<span class="left">市</span>
								</el-col>
								<el-col :span="4">
									<el-select filterable v-model="form.county_code" clearable placeholder="请选择"
										value-key="id">
										<el-option v-for="item in countyOption" :key="item.id" :label="item.name"
											:value="item">
										</el-option>
									</el-select>
								</el-col>
								<el-col :span="2" style='margin-left: 5px;'>
									<span class="left">区/县</span>
								</el-col>
							</el-row>
						</el-form-item> -->
						<el-form-item label="收货地址:" prop="address">
							<el-row>
								<el-col :span='15'>
									<el-input v-model="form.address" placeholder="请输入收货地址"></el-input>
								</el-col>
								<el-col :span='3'>
									<div style="font-size: 12px;color: #666666;margin-left: 5px;">用于奖品发放</div>
								</el-col>
							</el-row>
							<p class="store-text">提示：请上传符合国家法律法规的文件，如上传不符合法律法规的文件，一经查实，参赛作者将承担相关法律责任。</p>
						</el-form-item>
						<div style="padding-left: 120px;">
							<div style="margin-bottom: 15px;">
								<el-checkbox v-model="sureChecked" class="circular-checkbox">
									请勾选并确认
								</el-checkbox>
								《<span style="color: #FF383D;cursor: pointer;" @click.stop="showMoBan('sure')">课程征集大赛原创作品及素材授权声明确认</span>》
							</div>
							<el-button v-if='$route.query.id' type="primary" :loading="btnLoading" class='btn-primary' @click="submit('form')">修改</el-button>
							<el-button v-else type="primary" class='btn-primary' :loading="btnLoading" @click="submit('form')">立即提交</el-button>
						</div>
					</el-form>
				</div>
			</el-col>
		</el-row>
		<!-- <div>
			<div>
				请勾选并确认《<span style="color: #FF383D;cursor: pointer;">课程征集大赛原创作品及素材授权声明确认</span>》
			</div>
			<el-button v-if='$route.query.id' type="primary" :loading="btnLoading" class='btn-primary' @click="submit('form')">修改</el-button>
			<el-button v-else type="primary" class='btn-primary' :loading="btnLoading" @click="submit('form')">立即提交</el-button>
		</div> -->
		<!-- 文件预览 -->
		<el-dialog title="文件预览" :visible="showDoc == true || showPdf == true  || showImg == true || showMp4 == true || showMp3 == true" :width="fileWidth"
			class="dialog-div-pre-style" :before-close="closePreviewClick" center>
			<div v-if="showDoc == true" class="dialog-body-content-base-style">
				<iframe frameborder="0" :src="'https://view.officeapps.live.com/op/view.aspx?src=' + this.fileUrl"
					width='100%' height="800px">
				</iframe>
			</div>
			<div v-else-if="showPdf == true" class="dialog-body-content-base-style"
				style="justify-content: center; align-items: center">
				<embed :src="fileUrl" style="width: 100%; height: 80vh" />
			</div>
			<div v-else-if="showImg == true" class="dialog-body-content-base-style"
				style="justify-content: center; align-items: center;min-width: 40px;min-height: 40px;">
				<img :src="fileUrl" alt="" style="width: 100%;height: auto;max-height: 85vh;">
			</div>
			<div v-else-if="showMp4 == true" class="dialog-body-content-base-style"
				style="justify-content: center; align-items: center;">
				<video  style="max-height: 80vh;" :src="fileUrl" controls="controls" width="100%" object-fit='cover'></video>
			</div>
			<div v-else-if="showMp3 == true" class="dialog-body-content-base-style"
				style="justify-content: center; align-items: center;min-width: 300px;">
				<audio :src="fileUrl" controls="controls" width="100%" object-fit='cover'></audio>
			</div>
		</el-dialog>
		<el-dialog :title="exampleTitle" :visible.sync="visible" width="700px" center append-to-body>
			<div v-html="exampleContent"></div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		client
	} from '../util/ali_oss.js'
	import editor from '../components/editor.vue'
	export default {
		name: 'index',
		components: {
			editor
		},
		data() {
			return {
				rules: {
					cover: [{
						required: true,
						message: '请上传' + this.util.getKeyFrontValue('cover'),
						trigger: 'change'
					}],
					title: [{
						required: true,
						message: '请输入' + this.util.getKeyFrontValue('name'),
						trigger: 'change'
					}],
					course_category_id: [{
						required: true,
						message: '请选择' + this.util.getKeyFrontValue('category'),
						trigger: 'change'
					}],
					// hour: [{
					// 	required: true,
					// 	message: '请输入学时',
					// 	trigger: 'change'
					// }],
					stages: [{
						required: true,
						message: '请选择' + this.util.getKeyFrontValue('apply'),
						trigger: 'change'
					}],
					// regin: [{
					// 	required: true,
					// 	message: '请选择参赛地区',
					// 	trigger: 'change'
					// }],
					viewpoint: [{
						required: true,
						message: '请填写' + this.util.getKeyFrontValue('introduction'),
						trigger: 'change'
					}],
					description: [{
						required: true,
						message: '请填写' + this.util.getKeyFrontValue('description'),
						trigger: 'change'
					}],
					introduction: [{
						required: true,
						message: '请填写作者自荐',
						trigger: 'change'
					}],
					videoList: [{
						required: true,
						message: '请上传' + this.util.getKeyFrontValue('video'),
						trigger: 'change',
						validator: (rule, value, callback) => {
							if(this.videoList.length == 0) {
								callback(new Error('请上传' + this.util.getKeyFrontValue('video')))
							} else {
								callback()
							}
						},
					}],
					audioList: [{
						required: true,
						message: '请上传' + this.util.getKeyFrontValue('audio'),
						trigger: 'change',
						validator: (rule, value, callback) => {
							if(this.audioList.length == 0) {
								callback(new Error('请上传' + this.util.getKeyFrontValue('audio')))
							} else {
								callback()
							}
						},
					}],
					fileList: [{
						required: true,
						message: '请上传' + this.util.getKeyFrontValue('file'),
						trigger: 'change'
					}],
					infoName: [{
						required: true,
						message: '请填写姓名',
						trigger: 'change'
					}],
					affiliation: [{
						required: true,
						trigger: 'blur',
						validator: (rule, value, callback) => {
							if (this.form.municipal_code == '') {
								callback(new Error('请选择市'))
							} else if (this.form.county_code == '') {
								callback(new Error('请选择区县'))
							} else if(value == '') {
								callback(new Error('请填写所属单位'))
							} else {
								callback()
							}
						}
					}],
					affiliationType: [{
						required: true,
						message: '请选择单位类型',
						trigger: 'change'
					}],
					municipal_code: [{
						required: true,
						message: '请选择市',
						trigger: 'change'
					}],
					county_code: [{
						required: true,
						message: '请选择所属教育局',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请填写收货地址',
						trigger: 'change'
					}]
				},
				form: {
					// cover: [],
					//标题
					title: '',
					// 课程类型
					course_type: '',
					// 课程主题
					course_category_id: '',
					//学时
					hour:'',
					// 使用阶段
					stages: [],
					// 参赛地区
					regin: '',
					//观点分享
					viewpoint: '',
					//课程亮点
					introduction: '',
					// 案例正文
					description: '',
					// 附件
					// fileList: [],
					// 省
					provincial_code: {
						code: 510000,
						level: 2,
						name: "四川省"
					},
					//市
					municipal_code: '',
					// 区
					county_code: '',

					//参赛人信息
					infoName: '',
					infoPhone: '',
					affiliationType: 1,
					affiliation: '',
					address: '',
				},
				btnLoading: false,
				provincialOption: [],
				municipalOption: [],
				countyOption: [],
				// 课程主题
				// 劳动教育
				laborOption: [],
				// 综合实践
				comprehensiveOption: [],
				categoryOptions: [],
				//课程封面
				coverImgList: [],
				//课程附件
				filesList: [],
				// 课程视频
				videoList: [],
				// 课程音频
				audioList: [],
				// 单位类型列表
				affiliationOptions: [
					{
						name: '学校',
						value: 1
					},
					{
						name: '基地',
						value: 2
					},
					{
						name: '机构',
						value: 3
					}
				],

				color: '#67C23A',
				showProgress: false, //进度条的显示
				progress: 0, //进度条数据
				showProgress2: false, //进度条的显示
				progress2: 0, //进度条数据
				
				showProgressAudio: false,
				progressAudio: 0,
				showProgressVideo: false,
				progressVideo: 0,

				//文件预览
				showDoc: false,
				showPdf: false,
				showImg: false,
				showMp4: false,
				showMp3: false,
				fileUrl: "",
				fileWidth: '',

				list: [],
				listCheck: [],

				updateInfo: {},
				isUpdate: false,
				//参赛地区
				reginOptions: '',
				
				schoolList: [],
				schoolTotal: 0,
				page: 1,
				search_option_input: '',
				// 学校选择 选中的这一项
				selectItem: {},
				
				// 是否显示教育局
				region_name: JSON.parse(sessionStorage.getItem('region_name')),
				is_permit_confirm_region_code: JSON.parse(sessionStorage.getItem('region_detail')).is_permit_confirm_region_code,
				// 是否显示作者自荐
				is_permit_author_recommendation: JSON.parse(sessionStorage.getItem('region_detail')).is_permit_author_recommendation,
				// 是否显示音频视频
				is_permit_audio: JSON.parse(sessionStorage.getItem('region_detail')).is_permit_audio,
				is_permit_video: JSON.parse(sessionStorage.getItem('region_detail')).is_permit_video,
				// 是否显示案例正文
				is_permit_description: JSON.parse(sessionStorage.getItem('region_detail')).is_permit_description,
				// 附件是否必填
				is_require_file: JSON.parse(sessionStorage.getItem('region_detail')).is_require_file,
				
				// 查看实例
				// 实例列表
				courseExampleList: [],
				visible: false,
				exampleTitle: '查看示例',
				exampleContent: '',
				// 确认勾选底部版权声明
				sureChecked: false
			}
		},
		methods: {
			showMoBan(val) {
				if (val == 'sure') this.exampleTitle = '课程征集大赛原创作品及素材授权声明确认'
				else this.exampleTitle = '查看示例'
				if (this.courseExampleList.length > 0) {
					this.courseExampleList.forEach(item => {
						if (item.kind == val) this.exampleContent = item.content
					})
					this.visible = true
				} else {
					this.http.courseExampleDetail('?region_code=' + JSON.parse(sessionStorage.getItem('region_code'))).then(res => {
						this.courseExampleList = res.data.data.competition_course_example
						this.courseExampleList.forEach(item => {
							if (item.kind == val) this.exampleContent = item.content
						})
					})
					this.visible = true
				}
			},
			//获取富文本
			getElem(res) {
				this.form.description = res
			},
			//获取作者自荐标签
			getRecommendationList() {
				this.http.getRecommendationList().then((res) => {
					this.list = res.data.data.list
				})
			},
			selectChecked(i) {
				if (this.listCheck.includes(i)) {
					//includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
					//filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
					this.listCheck = this.listCheck.filter(function(ele) {
						return ele != i;
					});
				} else {
					this.listCheck.push(i);
				}
			},

			edit() {
				this.$router.go(-1)
			},
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.coverImgList.length == 0) {
							this.$message.error('请上传' + this.util.getKeyFrontValue('cover') + '!');
							return
						}
						if (this.form.stages.length == 0) {
							this.$message.error('请选择' + this.util.getKeyFrontValue('apply') + '!');
							return
						}
						if(this.form.viewpoint == ''){
							this.$message.error('请输入' + this.util.getKeyFrontValue('introduction') + '!');
							return
						}
						// if (this.listCheck.length == 0) {
						// 	this.$message({
						// 		message: '请选择自荐标签!',
						// 		type: 'error'
						// 	})
						// 	return
						// }
						if(this.is_permit_author_recommendation && !this.form.introduction){
							this.$message.error('请输入自荐内容！');
							return
						}
						// 课程正文
						// if(this.is_permit_description && !this.form.description) {
						// 	return this.$message.error('请输入' + this.util.getKeyFrontValue('description') + '!');
						// }
						if (this.videoList.length == 0) {
							this.$message.error('请上传' + this.util.getKeyFrontValue('video') + '!');
							return
						}
						if (this.audioList.length == 0) {
							this.$message.error('请上传' + this.util.getKeyFrontValue('audio') + '!');
							return
						}
						if (this.is_require_file && this.filesList.length == 0) {
							this.$message.error('请上传' + this.util.getKeyFrontValue('file') + '!');
							return
						}
						// if (!/^1[3456789]\d{9}$/.test(this.form.infoPhone)) {
						// 	this.$message({
						// 		message: '请填写正确的手机号!',
						// 		type: 'error'
						// 	})
						// 	return
						// }
						if (!this.sureChecked) {
							this.$message.error('请先勾选并确认《课程征集大赛原创作品及素材授权声明确认》!');
							return
						}
						this.$confirm('课程内容一经提交，无法修改、删除。请谨慎提交！', '提示', {
							confirmButtonText: '提交',
							cancelButtonText: '取消',
						}).then(() => {
							this.btnLoading = true
							let competition_course = {
								cover: this.coverImgList[0].path,
								name: this.form.title,
								course_category_id: this.form.course_category_id,
								introduction: this.form.viewpoint,
								author_recommendation: this.form.introduction,
								description: this.form.description,
								// competition_course_for_author_recommendation_signature: this.listCheck,
								apply_to_stages: JSON.parse(JSON.stringify(this.form.stages)),
								// hour:this.form.hour,
								// region_code: this.form.regin.code
							}
							if(this.is_permit_confirm_region_code) {
								// competition_course.confirm_region_code = this.form.county_code.code
								competition_course.confirm_region_code = 510184
							}
							let competition_course_files = this.filesList
							let competition_course_videos = this.videoList
							let competition_course_audios = this.audioList
							let competition_course_author = {
								name: this.form.infoName,
								// phone: this.form.infoPhone,
								affiliation_type: this.form.affiliationType,
								affiliation: this.form.affiliation,
								shipping_address: this.form.address,
								provincial_code: this.form.provincial_code.code,
								municipal_code: this.form.municipal_code.code,
								county_code: this.form.county_code.code
							}
							// if(this.form.affiliationType == 1) {
							// 	competition_course_author.affiliation = this.selectItem.name
							// 	competition_course_author.resident_school_id = this.form.affiliation
							// }
							let obj = {
								competition_course: competition_course,
								competition_course_files: competition_course_files,
								competition_course_videos: competition_course_videos,
								competition_course_audios: competition_course_audios,
								competition_course_author: competition_course_author
							}
							if (this.isUpdate) {
								this.http.update('?id=' + this.$route.query.id, obj).then((res) => {
									if (res.data.code == 200) {
										this.$message({
											message: '修改成功',
											type: 'success'
										});
										this.$router.push({
											name: 'course'
										})
									} else {
										this.btnLoading = false
										this.$message.error(res.data.msg);
									}
								}).catch(err => {
									this.btnLoading = false
								})
							} else {
								this.http.courseStore('?region_code=' + JSON.parse(sessionStorage.getItem('region_code')), obj).then((res) => {
									if (res.data.code == 200) {
										this.$message({
											message: '添加成功',
											type: 'success'
										});
										this.$router.push({
											name: 'course'
										})
									} else {
										this.btnLoading = false
										this.$message.error(res.data.msg);
									}
								}).catch(err => {
									this.btnLoading = false
								})
							}
						}).catch(() => {
							this.btnLoading = false
							return
						})
					} else {
						return false;
					}
				});

			},
			//编辑
			getDetail() {
				this.http.getDetail('?id=' + this.$route.query.id, '').then((res) => {
					if (res.data.code == 200) {
						let competition_course = res.data.data.competition_course
						this.coverImgList.push({
							name: competition_course.cover,
							url: this.alOssUrl + competition_course.cover,
							path: competition_course.cover
						})
						this.form.title = competition_course.name
						this.form.hour = competition_course.hour
						// this.form.county_code = competition_course.confirm_region_code
						setTimeout(() => {
							this.countyOption.map(item => {
								if (item.code == competition_course.confirm_region_code) {
									this.form.county_code = item
								}
							})
						}, 100)
						//课程主题
						this.getCourseCategoryTree()
						this.$nextTick(() => {
							if (competition_course.relate_course_category && competition_course.relate_course_category.kind) {
								this.form.course_type = competition_course.relate_course_category.kind
							}
							this.form.course_category_id = competition_course.course_category_id
						})
						// let obj = {
						// 	pid: '2259'
						// }
						// this.http.region('', obj).then((res) => {
						// 	this.reginOptions = res.data.data
						// 	res.data.data.map(item => {
						// 		if (item.code == competition_course.region_code) {
						// 			this.form.regin = item
						// 		}
						// 	})
						// })
						// 课程简介
						this.form.viewpoint = competition_course.introduction
						this.form.introduction = competition_course.author_recommendation
						// 正文
						this.form.description = competition_course.description
						//适用阶段
						this.form.stages = competition_course.apply_to_stages ? competition_course.apply_to_stages : []
						//自荐标签
						// competition_course.relate_competition_course_for_author_recommendation_signature.map((
						// 	item) => {
						// 	this.listCheck.push(item.relate_competition_course_author_recommendation_signature.id)
						// })
						// this.listCheck=this.filterArray(this.listCheck)
						this.filesList = res.data.data.competition_course_file
						this.videoList = res.data.data.competition_course_video
						this.audioList = res.data.data.competition_course_audio
						let competition_course_author = res.data.data.competition_course_author
						this.form.infoName = competition_course_author.name
						// this.form.infoPhone = competition_course_author.phone
						this.form.affiliationType = competition_course_author.affiliation_type
						this.form.affiliation = competition_course_author.affiliation
						// if(competition_course_author.affiliation_type == 1) {
						// 	this.form.affiliation = competition_course_author.resident_school_id
						// 	this.selectItem = {
						// 		id: competition_course_author.resident_school_id,
						// 		name: competition_course_author.affiliation
						// 	}
						// 	this.schoolList.push({
						// 		id: competition_course_author.resident_school_id,
						// 		name: competition_course_author.affiliation
						// 	})
						// }
						this.form.address = competition_course_author.shipping_address
						// 省市区
						this.http.region('', '').then((res) => {
							res.data.data.map((item) => {
								if (item.code == competition_course_author.provincial_code) {
									this.form.provincial_code = item
								}
							})
						})
						this.updateInfo = competition_course_author
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			filterArray(arr) {
				var arr = arr
				for (var i = 0; i < arr.length; i++) {
					if (arr[i] == '' || arr[i] == null || typeof(arr[i]) == undefined) {
						arr.splice(i, 1);
						i = i - 1;
					}
				}
				return arr
			},
			//参赛地区选择
			getRegion() {
				let obj = {
					pid: '2259'
				}
				this.http.region('', obj).then((res) => {
					this.reginOptions = res.data.data
				})
			},
			//初始化地区选择
			getRegionOption() {
				this.http.region('', '').then((res) => {
					this.provincialOption = res.data.data
				})
			},
			// 课程封面图片上传方法
			coverUpload(obj) {
				let that = this
				var fileName = this.getFileName.getFileName() + obj.file.name; //定义唯一的文件名
				//定义唯一的文件名
				client().multipartUpload(fileName, obj.file, {
					progress: function(p) {
						//p进度条的值
						setTimeout(function() {
							that.showProgress = true;
							that.progress = Math.floor(p * 100);
						}, 300)
					}
				}).then(
					result => {
						if (result.res.statusCode == 200) {
							let nweObj = {
								name: obj.file.name,
								url: this.alOssUrl + result.name,
								path: fileName,
								size: obj.file.name.size
							}
							delete this.rules.cover;
							this.coverImgList.push(nweObj)
						} else {
							this.$message.error('上传失败！');
						}
					})
			},
			// 课程视频上传方法
			videoUpload(obj) {
				let that = this
				var fileName = this.getFileName.getFileName() + obj.file.name; //定义唯一的文件名
				let size = this.util.getFileSize(obj.file.size)
				//定义唯一的文件名
				client().multipartUpload(fileName, obj.file, {
					progress: function(p) {
						//p进度条的值
						setTimeout(function() {
							that.showProgressVideo = true;
							that.progressVideo = Math.floor(p * 100);
						}, 300)
					}
				}).then(
					result => {
						if (result.res.statusCode == 200) {
							let nweObj = {
								name: obj.file.name,
								url: this.alOssUrl + result.name,
								path: fileName,
								size: size
							}
							delete this.rules.videoList;
							this.videoList.push(nweObj)
							console.log(this.videoList)
						} else {
							this.$message.error('上传失败！');
						}
					})
			},
			// 课程音频上传方法
			audioUpload(obj) {
				let that = this
				var fileName = this.getFileName.getFileName() + obj.file.name; //定义唯一的文件名
				let size = this.util.getFileSize(obj.file.size)
				//定义唯一的文件名
				client().multipartUpload(fileName, obj.file, {
					progress: function(p) {
						//p进度条的值
						setTimeout(function() {
							that.showProgressAudio = true;
							that.progressAudio = Math.floor(p * 100);
						}, 300)
					}
				}).then(
					result => {
						if (result.res.statusCode == 200) {
							let nweObj = {
								name: obj.file.name,
								url: this.alOssUrl + result.name,
								path: fileName,
								size: size
							}
							delete this.rules.audioList;
							this.audioList.push(nweObj)
						} else {
							this.$message.error('上传失败！');
						}
					})
			},
			handleRemove(file, fileList) {
				this.coverImgList = []
			},
			handlePictureCardPreview(file) {
				this.showFile(file.path)
			},
			beforeAvatarUpload(file) {
				// 限制图片大小格式
				const isJPG = file.type === 'image/jpg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isJPEG && !isPNG) {
					this.$message.error('上传图片只能是 JPG,JPEG和PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过5MB!');
				}
				return isJPG && isLt5M || isPNG || isJPEG;
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制上传 1 个文件！`);
			},
			// 课程附件上传方法
			success(obj) {
				let that = this
				var fileName = this.getFileName.getFileName() + obj.file.name; //定义唯一的文件名
				let size = this.util.getFileSize(obj.file.size)
				//定义唯一的文件名，打印出来的uid其实就是时间戳
				client().multipartUpload(fileName, obj.file, {
					progress: function(p) {
						//p进度条的值
						setTimeout(function() {
							that.showProgress2 = true;
							that.progress2 = Math.floor(p * 100);
						}, 300)
					}
				}).then(
					result => {
						if (result.res.statusCode == 200) {
							let nweObj = {
								name: obj.file.name,
								url: this.alOssUrl + result.name,
								path: fileName,
								size: size
							}
							delete this.rules.fileList;
							this.filesList.push(nweObj)
							console.log(this.filesList)
						} else {
							this.$message.error('上传失败！');
						}
					})
			},
			// 视频上传
			beforeVideoUpload(file) {
				const isLt1024M = file.size / 1024 / 1024 < 1024;
				if (!isLt1024M) {
					this.$message.error('上传文件大小不能超过 1024MB!');
					return false
				}
				const fileName = file.name;
				const format = 'mp4'
				//文件后缀
				const ext = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
				// 允许mp4
				let isExtent = true
				format.split(',').map((item) => {
					//只要有一个就匹配就返回false
					if (ext == item) {
						isExtent = false
						return isExtent
					}
				})
				if (isExtent) {
					this.$message.error(`当前文件只能上传${format}格式！`);
					return false
				} else {
					return true
				}
			},
			// 视频上传
			beforeAudioUpload(file) {
				const isLt50M = file.size / 1024 / 1024 < 50;
				if (!isLt50M) {
					this.$message.error('上传文件大小不能超过 50MB!');
					return false
				}
				const fileName = file.name;
				const format = 'ppt,pptx'
				//文件后缀
				const ext = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
				// 允许jpg
				let isExtent = true
				format.split(',').map((item) => {
					//只要有一个就匹配就返回false
					if (ext == item) {
						isExtent = false
						return isExtent
					}
				})
				if (isExtent) {
					this.$message.error(`当前文件只能上传PPT格式！`);
					return false
				} else {
					return true
				}
				
				// const isLt500M = file.size / 1024 / 1024 < 500;
				// if (!isLt500M) {
				// 	this.$message.error('上传文件大小不能超过 500MB!');
				// 	return false
				// }
				// const fileName = file.name;
				// const format = 'mp3'
				// //文件后缀
				// const ext = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
				// // 允许mp3
				// let isExtent = true
				// format.split(',').map((item) => {
				// 	//只要有一个就匹配就返回false
				// 	if (ext == item) {
				// 		isExtent = false
				// 		return isExtent
				// 	}
				// })
				// if (isExtent) {
				// 	this.$message.error(`当前文件只能上传${format}格式！`);
				// 	return false
				// } else {
				// 	return true
				// }
			},
			beforeAvatarUpload2(file) {
				const isLt50M = file.size / 1024 / 1024 < 50;
				if (!isLt50M) {
					this.$message.error('上传文件大小不能超过 50MB!');
					return false
				}
				const fileName = file.name;
				const format = 'doc,docx'
				//文件后缀
				const ext = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
				// 允许jpg
				let isExtent = true
				format.split(',').map((item) => {
					//只要有一个就匹配就返回false
					if (ext == item) {
						isExtent = false
						return isExtent
					}
				})
				if (isExtent) {
					this.$message.error(`当前文件只能上传Word格式！`);
					return false
				} else {
					return true
				}
			},
			
			delList(type, id) {
				if(type == 'file') {
					this.filesList.map((item, index) => {
						if (item.id == id) {
							this.filesList.splice(index, 1)
						}
					})
				} else if(type == 'video') {
					this.videoList.map((item, index) => {
						if (item.id == id) {
							this.videoList.splice(index, 1)
						}
					})
				} else if(type == 'audio') {
					this.audioList.map((item, index) => {
						if (item.id == id) {
							this.audioList.splice(index, 1)
						}
					})
				}
				
			},
			//查看文件
			showFile(url) {
				let type = url;
				this.fileUrl = this.alOssUrl + url
				if (type.indexOf("doc") != -1 || type.indexOf("docx") != -1 || type.indexOf("xsl") != -1 || type.indexOf(
						"xlsx") != -1 || type.indexOf("pptx") != -1 || type.indexOf("ppt") != -1) {
					this.fileWidth = '80%'
					this.showDoc = true
				} else if (type.indexOf("pdf") != -1) {
					this.fileWidth = '80%'
					this.showPdf = true
				} else if (type.indexOf("jpg") != -1 || type.indexOf("png") != -1 || type.indexOf("jpeg") != -1) {
					this.showImg = true
					let image = new Image();
					image.src = this.alOssUrl + url;
					image.onload = () => {
						this.fileWidth = image.width + 'px'
					};
				} else if (type.indexOf("mp4") != -1) {
					this.fileWidth = '70%'
					this.showMp4 = true
				} else if (type.indexOf("mp3") != -1) {
					this.fileWidth = '360px'
					this.showMp3 = true
				}else {
					this.$message("当前文件暂不支持预览")
				}
			},
			closePreviewClick() {
				if (this.showDoc == true) {
					this.showDoc = false
				} else if (this.showPdf == true) {
					this.showPdf = false
				} else if (this.showImg == true) {
					this.showImg = false
				} else if (this.showMp4 == true) {
					this.showMp4 = false
				} else if (this.showMp3 == true) {
					this.showMp3 = false
				}
			},
			affiliationTypeChange() {
				this.form.affiliation = ''
			},
			schoolSelectChange(val) {
				this.selectItem = this.schoolList.find((item)=>{
				  return item.id === val;
				});
			},
			getSchoolList(val) {
				let obj = this.util.delObjItem({
					page: this.page,
					limit: 15,
					sf1: this.search_option_input,
				})
				if(val == 'search') {
					this.page = 1
					obj.page = 1
				}
				this.http.getSchoolList('', obj).then(res => {
					this.schoolTotal = res.data.data.total
					if (res.data.data.list.length == 0) {
						this.$message.error('暂无搜索数据！');
						this.search_option_input = ''
					} else {
						this.schoolList = res.data.data.list
						let flag = true
						this.schoolList.forEach(item => {
							if(item.id == this.selectItem.id) {
								flag = false
							}
						})
						if(this.selectItem.id && flag) {
							this.schoolList.push(this.selectItem)
						}
					}
				})
			},
			// 上一页
			prevPage() {
				this.page = this.page - 1
				if (this.page < 1) {
					this.$message('已经是第一页！');
					this.page = 1
				}
				this.getSchoolList()
			},
			// 下一页
			nextPage() {
				let pageCount = (this.schoolTotal / 15)
				if (this.page < pageCount) { // 判断点击的页数是否小于总页数;
					this.page = this.page + 1
					this.getSchoolList()
				} else {
					this.$message('最后一页！');
				}
			},
			getMunicipalList() {
				let obj = {
					pid: '2259'
				}
				this.http.region('', obj).then((res) => {
					this.municipalOption = res.data.data
					// res.data.data.forEach(item => {
					// 	if(item.code == JSON.parse(sessionStorage.getItem('region_code'))) {
					// 		this.form.municipal_code = item
					// 	}
					// })
				})
			},
			getIsPermitConfirmRegionCode() {
				this.http.getCourseRegionDetail('?id=' + JSON.parse(sessionStorage.getItem('region_id'))).then(res => {
					this.is_permit_confirm_region_code = res.data.data.competition_course_region.is_permit_confirm_region_code
				})
			},
			// 获取课程主题
			getCourseCategoryTree() {
				return new Promise((resolve, reject) => {
					this.http.course_tree('?region_code=' + JSON.parse(sessionStorage.getItem('region_code'))).then(res => {
						this.categoryOptions = res.data.data.tree
						if (res.data.data.tree && res.data.data.tree.length > 0) {
							res.data.data.tree.forEach(item => {
								if (item.kind == 'labor') {
									// 劳动教育
									this.laborOption = item.children
								} else if (item.kind == 'comprehensive') {
									// 综合实践
									this.comprehensiveOption = item.children
								}
							})
						}
						resolve(res.data.data.tree)
					}).catch(err => {
						reject(err)
					})
				})
			},
			// 获取添加课程时单位类型列表
			getAffiliationList() {
				this.http.getAffiliationList('?region_code=' + JSON.parse(sessionStorage.getItem('region_code'))).then(res => {
					if(res.data.data.list.length > 0) {
						this.affiliationOptions = []
						res.data.data.list.forEach(item => {
							switch (item) {
								case 1:
									this.affiliationOptions.push({name:'学校',value: 1})
									break;
								case 2:
									this.affiliationOptions.push({name:'基地',value: 2})
									break;
								case 3:
									this.affiliationOptions.push({name:'机构',value: 3})
									break;
							}
						})
						this.form.affiliationType = res.data.data.list[0]
					} else {
						this.affiliationOptions = [{name: '学校',value: 1},{name: '基地',value: 2},{name: '机构',value: 3}]
					}
				})
			},
			stageChange(val) {
				this.form.stages = [val[val.length - 1]]
			}
		},
		mounted() {
			delete this.rules.cover;
			delete this.rules.fileList;
			delete this.rules.viewpoint
			delete this.rules.description
			// this.getRegion()
			// this.getRegionOption()
			// this.getRecommendationList()
			this.getSchoolList()
			// 获取省下面的市区
			this.getMunicipalList()
			// 获取是否需要上传教育局
			this.getIsPermitConfirmRegionCode()
			// 获取超管配置的课程主题
			this.getCourseCategoryTree()
			// 获取超管配置的单位类型列表
			this.getAffiliationList()
			sessionStorage.setItem("store", true)
			if (this.$route.query.id != undefined) {
				this.isUpdate = true
				this.getDetail()
			}
		},
		watch: {
			'form.course_type': {
				handler(res) {
					if (res === 'labor') {
						if (this.laborOption.length > 0) this.form.course_category_id = this.laborOption[0].id
						else this.form.course_category_id = ''
					} else if (res === 'comprehensive') {
						if (this.comprehensiveOption.length > 0) this.form.course_category_id = this.comprehensiveOption[0].id
						else this.form.course_category_id = ''
					}
				}
			},
			'form.provincial_code': {
				handler(res) {
					this.form.municipal_code = "";
					this.form.county_code = "";
					let obj = {
						pid: res.id
					}
					this.http.region('', obj).then((res) => {
						this.municipalOption = res.data.data
						if (this.$route.query.id != undefined) {
							res.data.data.map(item => {
								if (item.code == this.updateInfo.municipal_code) {
									this.form.municipal_code = item
								}
							})
						}
					})
				},
			},
			'form.municipal_code': {
				handler(res) {
					this.form.county_code = "";
					let obj = {
						pid: res.id
					}
					this.http.region('', obj).then((res) => {
						this.countyOption = res.data.data
						if (this.$route.query.id != undefined) {
							res.data.data.map(item => {
								if (item.code == this.updateInfo.county_code) {
									this.form.county_code = item
								}
							})
						}
					})
				},
			},

			progress: {
				handler(res) {
					if (res == 100) {
						let that = this
						setTimeout(function() {
							that.showProgress = false
							that.progress = 0
						}, 1000)
					}
				},
			},
			progress2: {
				handler(res) {
					if (res == 100) {
						let that = this
						setTimeout(function() {
							that.showProgress2 = false
							that.progress2 = 0
						}, 1000)
					}
				},
			},
			progressVideo: {
				handler(res) {
					if (res == 100) {
						let that = this
						setTimeout(function() {
							that.showProgressVideo = false
							that.progressVideo = 0
						}, 1000)
					}
				},
			},
			progressAudio: {
				handler(res) {
					if (res == 100) {
						let that = this
						setTimeout(function() {
							that.showProgressAudio = false
							that.progressAudio = 0
						}, 1000)
					}
				},
			},
		}
	}
</script>

<style scoped="scoped">
	.store-container {
		width: 75vw;
		min-width: 1000px;
		background-color: white;
		/* height: 650px; */
		margin: 0 auto;
		padding: 20px 10px 20px 25px;
		/* box-shadow: 0 0 100px rgba(31, 180, 249, 0.26); */
		box-sizing: border-box;
		margin-bottom: 30px;
	}

	.store-from-title {
		font-weight: bold;
		font-size: 20px;
		margin-top: 10px;
		margin-bottom: 20px;
		margin-left: 20px;
	}

	.store-from {
		margin-bottom: 20px;
		overflow-y: scroll;
		font-size: 14px;
		color: #333333;
	}

	.store-from-content {
		width: 100%;
	}

	/* 作者自荐 */
	.introduction {
		border: 1px solid #E5E5E5;
	}

	.introduction-line {
		width: 95%;
		height: 1px;
		background-color: #E5E5E5;
		margin: 10px auto;
	}

	.introduction-check-content {
		overflow: hidden;
	}

	/* 自荐标签选中 */
	.introduction-check-active {
		/* width: 110px; */
		padding: 0 26px;
		height: 42px;
		line-height: 42px;
		background: #D9F1FF;
		border-radius: 100px;
		color: #1FB4F9;
		text-align: center;
		font-weight: 400;
		cursor: pointer;
		float: left;
		margin-left: 10px;
		margin-top: 5px;
	}

	.introduction-check {
		/* width: 108px; */
		padding: 0 25px;
		height: 40px;
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		border-radius: 90px;
		text-align: center;
		color: #666666;
		font-weight: 400;
		cursor: pointer;
		float: left;
		margin-left: 10px;
		margin-top: 5px;
	}

	.introduction /deep/ .el-textarea__inner {
		border: none !important;
	}

	.store-from /deep/ .el-form-item__label {
		color: #333333 !important;
	}

	.el-checkbox.is-bordered+.el-checkbox.is-bordered {
		margin-left: 0;
	}

	.store-text {
		font-size: 12px;
		color: #666666;
	}

	/* 下载按钮 */
	.upload-button .el-button {
		width: 90px;
		height: 33px;
		border: 1px solid #FF3B3D;
		border-radius: 5px !important;
		font-weight: 400;
		color: #FF3B3D;
		border-radius: 0;
		font-size: 16px;
		margin-bottom: 10px;
		padding: 0;
		text-align: center;
		line-height: 33px;
	}
	.upload-button .el-button:hover {
		background: #FF3B3D;
		color: #FFFFFF;
	}
	.el-checkbox__input.is-checked .el-checkbox__inner {
		border-radius: 50%;
	}
	#wangeditor {
		z-index: 1;
	}
	/* 翻页 */
	.text-content {
		margin-top: 5px;
		width: 100%;
		text-align: center;
		display: flex;
		height: 30px;
		line-height: 30px;
	}
	
	.text-content .text {
		flex: 1;
		color: #5485fc;
		cursor: pointer;
		font-size: 14px;
		border-top: 1px solid #ececec;
	}
	
	.search_style {
		color: #5485fc;
		list-style: none;
		font-size: 14px;
		padding-left: 0;
		text-align: center;
		vertical-align: middle;
		height: 40px;
		line-height: 40px;
		margin-right: 10px;
	}
	.el_input_top_border /deep/ .el-input__suffix i {
		display: none;
	}
	.dialog-div-pre-style /deep/ .el-dialog__body {
		overflow-y: auto;
	}
	.circular-checkbox /deep/ .el-checkbox__inner{
		border-radius: 50%;
	}
</style>
