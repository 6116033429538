<template>
	<div id="wangeditor">
		<div ref="editorElem" style="text-align: left"></div>
	</div>
</template>
<script>
	import {
		client
	} from "../util/ali_oss.js";
	const E = window.wangEditor
	export default {
		data() {
			return {
				editor: "",
				editorHtml: '',
				isChange:false
			}
		},
		props: ['editorContent'],
		methods:{
			setEditor(res){
				this.editor.txt.html(res)
			},
			newEditor(){
				// 富文本
				this.editor = new E(this.$refs.editorElem);
				// 编辑器的事件，每次改变会获取其html内容
				this.editor.config.onchange = (html) => {
					this.isChange = true;
					this.editorHtml = html;
				};
				// //上传视频的方法
				this.editor.config.uploadVideoMaxSize = 1 * 1024 * 1024 * 50 // 50m
				// // 限制类型
				this.editor.config.uploadVideoAccept = ['mp4']
				this.editor.config.customUploadVideo = (resultFiles, insertVideoFn) => {
					// resultFiles 是 input 中选中的文件列表
					// insertVideoFn 是获取视频 url 后，插入到编辑器的方法
					let name = this.getFileName.getFileName()
					var fileName = name + resultFiles[0].name;
					client()
						.multipartUpload(fileName, resultFiles[0])
						.then((res) => {
							insertVideoFn(this.alOssUrl + res.name);
						})
				};
				this.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
					let name = this.getFileName.getFileName()
					var fileName = name + resultFiles[0].name;
					client().multipartUpload(fileName, resultFiles[0]).then((res) => {
						insertImgFn(this.alOssUrl + res.name)
					});
				}
				// 配置全屏功能按钮是否展示
				// this.editor.config.showFullScreen = false
				this.editor.create(); // 创建富文本实例
			}
		},
		mounted() {
			this.newEditor()
		},
		watch: {
			editorHtml: {
				handler(res) {
					this.$emit('editorHtml', res)
				},
				immediate: true
			},
			editorContent: {
				handler(res) {
					if(!this.isChange){
						this.setEditor(res)
					}
					this.isChange= false;
				},
			}
		},
	}
</script>
<style scoped>
	#wangeditor{
		position: relative;
	}
</style>
